<!--
 * @Author: SongYijie
 * @Date: 2020-05-06 17:54:45
 * @LastEditors: SongYijie
--> 
<template>
  <div class="main has-header has-footer">
    <mt-header fixed title="确认签约">
      <router-link to slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="tips">
      <img src="@assets/images/task/icon-protocol.png" alt />
      <h5 class="txt-black">
        您将于{{isXyk}}平台旗下服务公司，
        <br />签署灵活用工协议
      </h5>
    </div>
    <hr class="space" />
    <div class="check">
      <mt-cell
        title="查看协议模版"
        :to="{path:'/task/protocol-template/'+id}"
        is-link
      ></mt-cell>
    </div>
    
    <hr class="space" />
    <div class="footer-bt">
      <mt-button type="danger" size="large" @click="handleCheck">确认签约</mt-button>
    </div>
  </div>
</template>

<script>
import { receiveTask } from "@api/tasks";
export default {
  components: {},
  props: ["id"],
  data() {
    return {
       isXyk:window.location.origin.indexOf('h5.kylincloud-saas') > -1 ?'麒麟云服' :'僖游客'
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleCheck() {
      let data = {
        taskId: this.id - 0,
        state: 2,
        source: 1,
      };
      receiveTask(data)
        .then((res) => {
          if (res.code === 200) {
            this.$router.replace("/task/sign-success");
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang='less' scoped>
.tips {
  width: 100%;
  padding-top: 15%;
  text-align: center;
  img {
    margin: auto;
    width: 121px;
  }
}
.check {
  padding-left: 10px;
}
</style>